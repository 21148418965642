import {Layout, Menu, Icon} from 'antd'
import '../css/nav.css' 
import React, { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import SubMenu from 'antd/lib/menu/SubMenu';
import {paths as path} from '../utils'
import {withRouter, Link} from 'react-router-dom'


const {Header} = Layout
function NavBar(props){
    const mobileBreakPoint = 830
    const throttleViewportChange = 250
    const closeOnClick = true
    const [viewportWidth, setViewportWidth] = useState(0);
    const [isMenuShown, setIsMenuShown] = useState(false);
    const isMobile = () => viewportWidth < mobileBreakPoint;
    const onLinkClick = (e) => {return isMobile() ? setIsMenuShown(false) : null;}

    useEffect(() => {
        setViewportWidth(window.innerWidth);
        const throttledSetViewPortWidth = throttle(() => setViewportWidth(window.innerWidth), throttleViewportChange);
        window.addEventListener('resize', throttledSetViewPortWidth);

        return () => window.removeEventListener('resize', throttledSetViewPortWidth);
    }, []);

    return (<React.Fragment>
    <Layout className="maincontent">
    <Header style={{padding:'0px', background:'#ffff'}} className="topnavbar">
    
    <a href="/home"><div className="logo"></div></a>
    <div className="menu_icon" style={{visibility:isMobile()?"visible":"hidden"}} onClick={()=>{setIsMenuShown(!isMenuShown)}}><Icon type="menu"/></div>
    
    <Menu
        theme="light"
        mode={isMobile()?"inline":"horizontal"}
        defaultSelectedKeys={[window.location.pathname]}
        style={{ lineHeight: '64px', float:'right'}}
        visible={!isMobile() || isMenuShown}

      >
        <Menu.Item key={path.home} onClick={onLinkClick}><Link to={path.home}>Home</Link></Menu.Item>
        <Menu.Item key={path.about} onClick={onLinkClick}><Link to={path.about}>About</Link></Menu.Item>
        <SubMenu key={'path.events.veda'} title={<span><Link to={"#"} style={{color:"rgba(0, 0, 0, 0.65)"}}>Events</Link></span>} >
            <Menu.Item key={path.events.veda} onClick={onLinkClick}><Link to={path.events.veda}>Veda</Link></Menu.Item>
            <Menu.Item key={path.events.vaidya} onClick={onLinkClick}><Link to={path.events.vaidya}>Vaidya</Link></Menu.Item>
            <Menu.Item key={path.events.vidya} onClick={onLinkClick}><Link to={path.events.vidya}>Vidya</Link></Menu.Item>
            <Menu.Item key={path.events.vignan} onClick={onLinkClick}><Link to={path.events.vignan}>Vignan</Link></Menu.Item>
            <Menu.Item key={path.events.viveka} onClick={onLinkClick}><Link to={path.events.viveka}>Viveka</Link></Menu.Item>
            <Menu.Item key={path.events.orphanage} onClick={onLinkClick}><Link to={path.events.orphanage}>Interaction at Orphanage</Link></Menu.Item>
        </SubMenu>
        <Menu.Item key={path.media} onClick={onLinkClick}><Link to={path.media}>Media</Link></Menu.Item>
      </Menu>

    </Header>
    </Layout>
    </React.Fragment>)
}

export default withRouter(NavBar)
