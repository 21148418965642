import React from 'react';
import logo from './logo.svg';
import './css/App.css';
import NavBar from './Components/navbar'
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import SiteFooter from './Components/footer';
import Home from './Pages/home';
import About from './Pages/about';
import Events from './Pages/events';
import Media from './Pages/media';

function App() {
  return (
    <React.Fragment>
      <Router basename={process.env.PUBLIC_URL}>
      <NavBar/>
      <Route path="/home" exact component={Home}/>
      <Route path="/aboutus" exact component={About}/>
      <Route path="/event/:event(veda|vaidya|vignan|viveka|vidya|Interaction at Orphanage)" exact component={props => <Events {...props}/>}/>
      <Route path="/media" exact component={Media}/>
      <Route path="/" exact component={Home} />
      <Route component={props => <Redirect to='/' {... props}/>}/>
      <SiteFooter/>
      </Router>
    </React.Fragment>
            
  );
}

export default App;
