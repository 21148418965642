import React from 'react'
import { Card, Modal, Skeleton, Spin, Row, Col } from 'antd';
import { Button } from 'antd/lib/radio';
import '../css/media.css'

class GalaryCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            modal:false
        }
    }
    componentDidMount(){
        this.setState({loading:false})
    }
    hideModal = ()=>{
        this.setState({modal:false})
    }
    showModal = ()=>{
        this.setState({modal:true})
    }
    render(){
       return(
        this.state.loading?(<Skeleton/>):(<div>
            <Button ghost type="link" className="unstyled-button" onClick={this.showModal}>
                <Card
                cover={
                    <img className="blockimage"  src={this.props.image}/>
                }
                bodyStyle={{visibility:"hidden", padding:0}}
                >
                </Card>
                <Modal
                visible={this.state.modal}
                onCancel={this.hideModal}
                footer={null}
                width={"80%"}
                >
                    <img className="blockimage" src={this.props.image}/>
                </Modal>
            </Button>
        </div>)
       )
    }
}
class Media extends React.Component{
    constructor(props){
        super(props);
        this.state={
            images:[]
        }
    }
    componentDidMount(){
        fetch('/media.json')
            .then((body)=>{return body.json()})
            .then((data)=>{
                this.setState({images:data})
            })
    }
    render(){
        return(
            <React.Fragment>
                <div className="maincontent media">
                    <Row gutter={20}>
                    {this.state.images.map((image)=>{return (
                        <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                            <GalaryCard image={'images/media/'+image}/>
                        </Col>
                        )})}
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default Media;