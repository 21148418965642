export const paths = {
    home:'/',
    about:'/aboutus',
    events:{
        veda:'/event/veda',
        vaidya:'/event/vaidya',
        vignan:'/event/vignan',
        viveka:'/event/viveka',
        vidya:'/event/vidya',
        orphanage:'/event/Interaction at Orphanage'
    },
    media:'/media'
}
