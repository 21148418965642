import React from 'react'
import { Card, Row, Col, Descriptions } from 'antd';
import '../css/events.css'
import {capitalize} from 'lodash'

const { Meta } = Card;

class Events extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            event_name:'',
            desc:"",
            events:[],
        };

    }
    updateEventData(event_name){
        this.setState({event_name:event_name})
        fetch('/'+event_name+'.json')
                .then((resp)=>{return resp.json()})
                .then((data)=>{this.setState({
                    desc:data.desc,
                    events:data.events
                })})
    }
    componentDidMount(){
        this.updateEventData(this.props.match.params.event)
        
    }
    componentWillReceiveProps(nextprops){
        if(nextprops.match.params.event!==this.props.match.params.event){
            this.updateEventData(nextprops.match.params.event)
        }
    }
    render(){
        return (
            <React.Fragment>
                <div className="maincontent">
                <div className="event_title">{this.state.event_name}</div>
                <div className="event_body">{this.state.desc}</div>
                <Row gutter={24}>
                        {this.state.events.map((event)=>{
                            return (<Col xs={24} sm={24} md={24} lg={12} xl={12}><Card className="event_card"
                                cover={
                                    <img
                                    alt={event.title}
                                    src={event.image}
                                    />
                                }
                                bodyStyle={{padding:0}}
                                >
                                <Descriptions
                                    title={<div className="event_sub_title">{event.title}</div>}
                                    bordered
                                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                >
                                    {Object.keys(event).map((key)=>{
                                        if((key!=="title")&&(key!=='image')){
                                            return (get_descriptions(event[key], key))
                                        }
                                    })}
                                </Descriptions>
                            </Card></Col>)
                        })}
                </Row>
                </div>
            </React.Fragment>
        )
    }
}


function get_descriptions(object_, key){
    if(Array.isArray(object_)){
        return (
            <Descriptions.Item label={capitalize(key)}>
            <ol>
                {object_.map(
                    (item)=>{
                        return(
                                <li>{item}</li>
                                )
                            }
                        )}
            </ol>
        </Descriptions.Item>
        )
    }
    else{
        return (
        <Descriptions.Item label={capitalize(key)}>
            {object_}    
        </Descriptions.Item>
        )
    }
}
export default Events;