import React from 'react'
import '../css/about.css'
import QueueAnim from 'rc-queue-anim';
import {Row, Col, Typography, Button, Icon, Statistic} from 'antd'
const {Title, Paragraph} = Typography

class About extends React.Component{
    constructor(){
        super();
        this.state = {
            loading:false,
            members:[],
        };

    }
    componentDidMount(){
        this.setState({loading: false})
        fetch('/team.json')
                .then((resp)=>{return resp.json()})
                .then((data)=>{
                  this.setState({
                    loading: true,
                    members: data.members
                })})
    }
    render(){
      return (
          <React.Fragment>
            <div className="section firstBanner">
                <Row gutter={2}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h1 className="heading">About Us</h1>
                    </Col>
                </Row>
            </div>

            <div className="maincontent">
              <div className="aboutus">
                <div className="ourstory">
                    <div>
                      <Row gutter={2}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <p className="aboutus_heading">Our Story</p>
                          </Col>
                      </Row>
                    </div>

                    <div className="ourstory">

                          <div>
                            <Row gutter={2}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <p className="aboutus_content">Scio has been very active since its inception.
                                Through competitions and counselling sessions throughout Hyderabad,
                                they meet and interact with a lot of students. They recently held Veda,
                                an inter school fest ,here in the BITS campus. The infrastructure of the college,
                                combined with guest lectures by some eminent personalities, along with a host of competitions,
                                saw a lot of participants come out with stars in their eyes.</p>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row gutter={2}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <p className="aboutus_content">While their work in the education field
                                is social service in one sense, all the profits generated is used for the benefit
                                and development of the underprivileged. The Scio For Society Plan covers a lot of
                                orphanages and undeveloped schools, providing them with self sustenance plans, educational materials,
                                teachers and increasing health awareness.</p>
                              </Col>
                            </Row>
                          </div>

                    </div>
                </div>

                <div className="ourvision">
                    <div>
                      <Row gutter={2}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <p className="aboutus_heading">Our Vision</p>
                          </Col>
                      </Row>
                    </div>

                    <div className="ourvision">

                          <div key="anim-1">
                            <Row gutter={2}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <p className="aboutus_content">Scio is a non-profit organization that aims at forming a support structure for the current education system. In view of the current choices that students make, succumbing to peer and parental pressure, the need for an unbiased voice is really felt. They intend to reach out to students and counsel them to so that they can choose their career paths wisely. You could say we’re a small charity with big ambitions. We’re directly transforming the lives of tens of thousands of children. And we’re campaigning to improve the lives of millions more. Imagine what we could achieve with your help.</p>
                              </Col>
                            </Row>
                          </div>

                    </div>
                </div>
              </div>
            </div>

            <div className="maincontent">
              <div className="whatwedo">
              <Row gutter={2}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <p className="whatwedo_heading">What We Do</p>
                  </Col>
              </Row>
              <Row gutter={48}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <img alt="empower" className="whatwedo_image" src={require("../images/aboutus_empower.png")}/>
                      <p className="whatwedo_title">Empower</p>
                      <p className="whatwedo_content">Empowerment refers to increasing the economic, political, social, or educational strength of individuals and communities.</p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <img alt="enlighten" className="whatwedo_image" src={require("../images/aboutus_enlighten.png")}/>
                      <p className="whatwedo_title">Enlighten</p>
                      <p className="whatwedo_content">Enlightenment refers to the increase in knowledge, both for an individual or the population as a whole integral factor.</p>
                  </Col>
              </Row>
              <Row gutter={48}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <img alt="educate" className="whatwedo_image" src={require("../images/aboutus_educate.png")}/>
                      <p className="whatwedo_title">Educate</p>
                      <p className="whatwedo_content">Education is a form of learning in which the knowledge and skills of a group are transferred from one generation to the next.</p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <img alt="advocacy" className="whatwedo_image" src={require("../images/advocacy.png")}/>
                      <p className="whatwedo_title">Advocacy</p>
                      <p className="whatwedo_content">Helping students get their voices heard and their rights met, and helping people to protect their children better.</p>
                  </Col>
              </Row>
              </div>
            </div>


            <div className="maincontent">
              <div className="ourteam">
              <Row gutter={2}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <p className="ourteam_heading">Our Team</p>
                  </Col>
              </Row>
              <Row gutter={48}>
                  {this.state.members.map((member)=>{
                    return (
                      <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                          <div className="ourteam_zoom">
                            <img alt={member.name} className="ourteam_image" src={member.image}/>
                            <p className="ourteam_title">{member.name}</p>
                          </div>
                      </Col>
                  )
                })}
              </Row>
              </div>
            </div>
          </React.Fragment>
      )
    }
}

export default About;
