import React from 'react'
import {Layout, Row, Col} from 'antd'
import '../css/footer.css'

const { Footer } = Layout;

class SiteFooter extends React.Component{
    constructor(props){
        super(props)
        this.state={}
    }
    render(){
        return (
            <React.Fragment>
                <Footer>
                    <div className="maincontent">
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                        <div className="flogo">
                            <a href="/home">
                                <img src={require("../images/scio_200_63_px.jpg")}/>
                            </a>
                            <div>BITS-Pilani | Manipal</div>
                        </div>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        </Col>
                        <Col span={24}><div className="copyright">©{new Date(Date.now()).getFullYear()} Scio Benevolent Foundation All Rights Reserved</div></Col>
                    </Row>
                    </div>
                </Footer>

            </React.Fragment>
        )
    }
}

export default SiteFooter