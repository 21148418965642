import React from 'react'
import '../css/home.css'
import QueueAnim from 'rc-queue-anim';
import {Row, Col, Typography, Button, Icon, Statistic} from 'antd'
const {Title, Paragraph} = Typography

const messengersvg = require('../images/messenger-line.svg')
function Home(props){
return (
    <React.Fragment>
        <div className="section firstBanner">
            <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            >
            <div className="maincontent">
                <Row gutter={2}>
                    <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                        <h1 className="page_title">Together For A Change</h1>
                        <Paragraph className="page_quote">Change starts with us as individuals. If one individual becomes more compassionate it will influence others and so we will change the world.</Paragraph>
                        <Paragraph className="page_quote">- Dalai Lama</Paragraph>
                        <a href="http://m.me/sciofoundation" target="_blank"> <Button className="contact_button" type="primary" size='large'><Icon type="message" />Lets bring about a change</Button> </a>
                    </Col>
                    <div className="hands_wrapper">
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <img alt="helping hands" className="hands_image" src={require("../images/helping_hand.png")}/>
                        </Col>
                    </div>
                </Row>
            </div>
            </QueueAnim>
        </div>
        <div className="maincontent">
            <div className="values section">
            <p className="values_heading">Values</p>
                <Row gutter={48}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <img alt="empower" className="values_image" src={require("../images/empower.png")}/>
                        <p className="values_title">Empower</p>
                        <p className="values_content">Empowerment refers to increasing the economic, political, social, or educational strength of individuals and communities.</p>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <img alt="enlighten" className="values_image" src={require("../images/enlighten.png")}/>
                        <p className="values_title">Enlighten</p>
                        <p className="values_content">Enlightenment refers to the increase in knowledge, both for an individual or the population as a whole integral factor.</p>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <img alt="educate" className="values_image" src={require("../images/educate.png")}/>
                        <p className="values_title">Educate</p>
                        <p className="values_content">Education is a form of learning in which the knowledge and skills of a group are transferred from one generation to the next.</p>
                    </Col>
                    
                </Row>
            </div>
        </div>
        <div className="home_stats_full">
            <div className="maincontent">
                <div className="home_stats">
                    <p style={{textAlign:"center", fontSize:"36px"}}>Scio over the years</p>
                    <p className="home_stats_content">Scio has been very active since its inception. Through competitions and counselling sessions throughout Hyderabad, they meet and interact with a lot of students. They recently held Veda, an interschool fest ,here in the BITS campus. The infrastructure of the college, combined with guest lectures by some emminent personalities , along with a host of competitions, saw a lot of participants come out with stars in their eyes.</p>
                    <p className="home_stats_content">While their work in the education field is social service in one sense, all the profits generated is used for the benefit and development of the underprivileged. The Scio For Society Plan covers a lot of orphanges and undeveloped schools, providing them with self sustenance plans, educational materials, teachers and increasing health awareness.</p>
                        <div className="home_stats_stats_block">
                            <Row gutter={48}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                    <Statistic className="home_stats_statistic" title="BENEFICIARIES" value={35000} />
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                    <Statistic className="home_stats_statistic" title="MEMBERS" value={15} />
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                    <Statistic className="home_stats_statistic" title="VOLUNTEERS" value={550} />
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                    <Statistic className="home_stats_statistic" title="Chapters" value={3} />
                                </Col>
                                
                            </Row>
                        </div>
                    </div>
            </div>
        </div>

        <div className = "sponsors">
            <div className = "maincontent ">
                <p style={{textAlign:"center", fontSize:"36px"}}>Our Sponsors</p>
                <div className="ticker_wrap">
                    <div className="ticker">
                        <img className="ticker__item" alt="sponsors_raymond" src={require('../images/sponsors_raymond.jpg')}/>
                        <img className="ticker__item" alt="sponsors_aditya_caterers" src={require('../images/sponsors_aditya_caterers.jpg')}/>
                        <img className="ticker__item" alt="sponsors_coramandel" src={require('../images/sponsors_coramandel.jpg')}/>
                        <img className="ticker__item" alt="sponsors_andhra_bank" src={require('../images/sponsors_andhra_bank.jpg')}/>
                        <img className="ticker__item" alt="sponsors_disha_charitable_trust" src={require('../images/sponsors_disha_charitable_trust.jpg')}/>
                        <img className="ticker__item" alt="sponsors_hetero" src={require('../images/sponsors_hetero.jpg')}/>
                        <img className="ticker__item" alt="sponsors_india_cements" src={require('../images/sponsors_india_cements.jpg')}/>
                        <img className="ticker__item" alt="sponsors_krishnapatnam_port" src={require('../images/sponsors_krishnapatnam_port.jpg')}/>
                        <img className="ticker__item" alt="sponsors_nagarjuna_cements" src={require('../images/sponsors_nagarjuna_cements.jpg')}/>
                        <img className="ticker__item" alt="sponsors_hmtv" src={require('../images/sponsors_hmtv.jpg')}/>
                        <img className="ticker__item" alt="sponsors_sew" src={require('../images/sponsors_sew.jpg')}/>
                        <img className="ticker__item" alt="sponsors_sew" src={require('../images/sponsors_vnr.jpg')}/>
                        <img className="ticker__item" alt="sponsors_raymond" src={require('../images/sponsors_raymond.jpg')}/>
                        <img className="ticker__item" alt="sponsors_aditya_caterers" src={require('../images/sponsors_aditya_caterers.jpg')}/>
                        <img className="ticker__item" alt="sponsors_coramandel" src={require('../images/sponsors_coramandel.jpg')}/>
                        <img className="ticker__item" alt="sponsors_andhra_bank" src={require('../images/sponsors_andhra_bank.jpg')}/>
                        <img className="ticker__item" alt="sponsors_disha_charitable_trust" src={require('../images/sponsors_disha_charitable_trust.jpg')}/>
                        <img className="ticker__item" alt="sponsors_hetero" src={require('../images/sponsors_hetero.jpg')}/>
                        <img className="ticker__item" alt="sponsors_india_cements" src={require('../images/sponsors_india_cements.jpg')}/>
                        <img className="ticker__item" alt="sponsors_krishnapatnam_port" src={require('../images/sponsors_krishnapatnam_port.jpg')}/>
                        <img className="ticker__item" alt="sponsors_nagarjuna_cements" src={require('../images/sponsors_nagarjuna_cements.jpg')}/>
                        <img className="ticker__item" alt="sponsors_hmtv" src={require('../images/sponsors_hmtv.jpg')}/>
                        <img className="ticker__item" alt="sponsors_sew" src={require('../images/sponsors_sew.jpg')}/>
                        <img className="ticker__item" alt="sponsors_sew" src={require('../images/sponsors_vnr.jpg')}/>
                    </div>
                </div>
                {/* <Row gutter={48}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_raymond" src={require('../images/sponsors_raymond.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_aditya_caterers" src={require('../images/sponsors_aditya_caterers.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_coramandel" src={require('../images/sponsors_coramandel.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_andhra_bank" src={require('../images/sponsors_andhra_bank.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_disha_charitable_trust" src={require('../images/sponsors_disha_charitable_trust.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_hetero" src={require('../images/sponsors_hetero.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_india_cements" src={require('../images/sponsors_india_cements.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_krishnapatnam_port" src={require('../images/sponsors_krishnapatnam_port.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_nagarjuna_cements" src={require('../images/sponsors_nagarjuna_cements.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_hmtv" src={require('../images/sponsors_hmtv.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_sew" src={require('../images/sponsors_sew.jpg')}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                        <img alt="sponsors_sew" src={require('../images/sponsors_vnr.jpg')}/>
                    </Col>
                </Row> */}
            </div>
        </div>
    </React.Fragment>
)
}


export default Home